import { render, staticRenderFns } from "./SmartAssetEditor.vue?vue&type=template&id=230f1219&scoped=true&"
import script from "./SmartAssetEditor.vue?vue&type=script&lang=js&"
export * from "./SmartAssetEditor.vue?vue&type=script&lang=js&"
import style0 from "./SmartAssetEditor.vue?vue&type=style&index=0&id=230f1219&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230f1219",
  null
  
)

export default component.exports